<template>
  <div class="elv-match-detail-dialog-content-table-detail">
    <h6>
      {{ t('report.transfer') }}:<span>{{ props.tableList?.length || 0 }}</span>
    </h6>
    <el-table
      ref="tableListRef"
      :data="props.tableList"
      border
      stripe
      max-height="500"
      class="elv-reconciliation-match-detail-table"
      header-cell-class-name="elv-reconciliation-match-detail-table-header__cell"
      header-row-class-name="elv-reconciliation-match-detail-table-header"
      row-class-name="elv-reconciliation-match-detail-table-row"
      cell-class-name="elv-reconciliation-match-detail-table-row__cell"
    >
      <el-table-column width="32" label="">
        <template #default="{ row }">
          <div class="elv-match-detail-dialog-content-item-transfer-type">
            <SvgIcon :name="transactionTypeData(row?.type).icon || ''" width="16" height="16" />
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="t('common.dateTime')">
        <template #default="{ row }">
          <div class="elv-match-detail-dialog-content-item-datetime">
            <span>
              {{
                dayjs(row?.datetime)
                  .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                  .format('YYYY/MM/DD HH:mm')
              }}</span
            >
            <span v-if="row?.txHash"
              >{{ middleEllipsis(row?.txHash)
              }}<img
                src="@/assets/img/copy.png"
                alt=""
                class="elv-financials-cell-date-time-main-bottom__copy"
                @click.stop="onClickedCopy(row?.txHash)"
            /></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="80" :label="t('common.currency')">
        <template #default="{ row }">
          <IconWithTitleCell
            :title="row?.underlyingCurrency?.showSymbol || ''"
            :icon="row?.underlyingCurrency?.logo || ''"
            titleFontFamily="Barlow"
            :titleWeight="400"
            titleSize="13px"
          />
        </template>
      </el-table-column>
      <el-table-column width="110" header-align="right" :label="t('common.amount')">
        <template #default="{ row }">
          <IconWithTitleCell
            class="elv-reconciliation-match-detail-table-row__cell-amount"
            :title="formatNumberToSignificantDigits(row?.amount ?? 0, 2, '', false) || ''"
            icon=""
            titleFontFamily="Barlow"
            :titleWeight="500"
            titleSize="13px"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { flatMap } from 'lodash-es'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import timezone from 'dayjs/plugin/timezone'
import { transactionType } from '@/config/index'
import { useEntityStore } from '@/stores/modules/entity'
import { middleEllipsis, formatNumberToSignificantDigits } from '@/lib/utils'
import IconWithTitleCell from '@/components/Base/Table/Cell/IconWithTitleCell.vue'

const props = defineProps({
  tableList: {
    type: Array,
    required: true
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)

const { t } = useI18n()
const entityStore = useEntityStore()
const { toClipboard } = useClipboard()

const transactionTypeData = computed(() => (type: string) => {
  let data = { label: '', value: '', icon: '' }
  const transactionTypeList = flatMap(transactionType, 'list')
  transactionTypeList.forEach((item) => {
    if (item.value === type) {
      data = item
    }
  })
  return data
})

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}
</script>

<style lang="scss" scoped>
.elv-match-detail-dialog-content-table-detail {
  width: 409px;

  h6 {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    line-height: 15px;

    span {
      font-family: 'Barlow';
      font-weight: 500;
      color: #636b75;
      margin-left: 4px;
      padding-top: 2px;
    }
  }
}
</style>
